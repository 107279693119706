import { render, staticRenderFns } from "./cate.vue?vue&type=template&id=5dda8f55&scoped=true&"
import script from "./cate.vue?vue&type=script&lang=js&"
export * from "./cate.vue?vue&type=script&lang=js&"
import style0 from "./cate.vue?vue&type=style&index=0&id=5dda8f55&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5dda8f55",
  null
  
)

export default component.exports