<template>
  <div class="productList">
    <van-search v-model="searchValue" placeholder="搜索商品" />
    <!-- <van-dropdown-menu z-index="1">
      <van-dropdown-item v-model="value1" :options="option1" />
      <van-dropdown-item v-model="value2" :options="option2" />
    </van-dropdown-menu> -->
    <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
      <div class="list">
        <van-list
        style="padding: 12px 6px"
          v-model="loading"
          :finished="finished"
          @load="onLoad"
        >
          <div span="12" class="list-item" v-for="(item, index) in list" :key="index" @click="goDetail(item.id)">
            <div class="image" :style="{backgroundImage: 'url(' + item.image  + '?x-oss-process=image/resize,w_680/quality,q_60)'}">
            </div>
            <div class="intro">
              <div class="intro-title">{{ item.store_name }}</div>
              <div style="display: flex; justify-content: space-between;">
                <div class="intro-price" v-if="item.dis_price == '0.00'">￥{{ item.price }}</div>
                <div class="intro-price" v-else>￥{{ item.dis_price }}<s>{{ item.price }}</s></div>
                <div class="intro-num">已售0</div>
              </div>
            </div>
          </div>
          <!-- <div class="list-item" v-for="(item, index) in list" :key="index">
            <div class="image" :style="{backgroundImage: 'url(' + item.image + ')'}">
            </div>
          </div> -->
          <van-empty v-if="!list.length && finished" description="暂无商品" />
          <div slot="finished" v-if="list.length && finished">暂无更多</div>
        </van-list>
      </div>
    
    </van-pull-refresh>
  </div>
</template>
<script>
import { h5ProductIndex } from '@/api/shop'
import Vue from 'vue';
import { Image as VanImage, Search, PullRefresh, DropdownMenu, DropdownItem, List, Empty  } from 'vant';
Vue.use(VanImage);
Vue.use(Search);
Vue.use(PullRefresh);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(List);
Vue.use(Empty);
export default  {
  beforeRouteEnter(to,from,next) {
    if (from.path == '/shop/detail') {
      next()
    } else {
      next((vm) => {
        // console.log(vm)
        vm.pagination.current = 1
        vm.pagination.count = 0
        vm.finished = false
        vm.list = []
        vm.cateId = vm.$route.query.cateId
        vm.onLoad()
      })
    }
    
  },
  // beforeRouteLeave (to, from, next) {
  //   console.log(this.$router.options.routes)
  //   console.log(from)
    
  //   if (from.path == '/shop/cate') {
  //     from.meta.keepAlive = false
  //   }
  //   next()
  // },
  data() {
    return {
      isLoading: false,
      loading: false,
      finished: false,
      list: [],
      cateId: '',
      searchValue: '',

      pagination: {
        current: 1,
        pageSize: 10,
        count: 0
      },
    }  
  },
  created () {
    this.cateId = this.$route.query.cateId || ''
  },
  methods: {
    goDetail (id) {
      this.$router.push({ path: '/shop/detail', query: { id: id } })
    },
    onRefresh () {
      this.pagination.current = 1
      this.list = []
      this.isLoading = false
      this.finished = false
      this.onLoad()
    },
    onLoad () {
      this.loading = true
      let params = {
        cate_id: this.cateId,
        page: this.pagination.current,
        page_size: this.pagination.pageSize
      }
      // if (this.searchValue) {
      //   params.store_name = this.searchValue
      // }
      h5ProductIndex(params).then(res => {
        if (res.code == 200) {
          this.list = this.list.concat(res.data.lists.data)
        }
        this.loading = false
        this.isLoading = false

        if (this.list.length == res.data.lists.total )  {
          this.finished = true
        } else {
          this.pagination.current++
        }
      }, () => {
        this.finished = true
        this.loading = false
        this.isLoading = false
      })
      
    }
  }
}
</script>

<style scoped lang="less">
.productList {
  background-color: #f5f5f5;
}
.list {
  min-height: calc(100vh - 54px);  
  background-color: #f5f5f5;

  &-item {
    margin-bottom: 12px;
    margin: 0 6px 12px;
    width: calc(50% - 12px);
    display: inline-block;
    
    .intro {
      background-color: #fff;
      padding: 10px;

      &-title {
        margin-bottom: 20px;
        line-height: 16px;
        overflow: hidden;
        -webkit-line-clamp: 2;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
      }

      &-price {
        color: #ff8c00;

        s {
          color: #999;
          font-size: 11px;
          margin-left: 2px;
        }
      }

      &-num {
        font-size: 12px;
        color: #999;
      }
    }

    .image {
      width: 100%;
      padding-top: 100%;
      background-size: cover;
      background-position: center;
    }
  }
}



</style>