<template>
  <div class="cate">
    <van-tree-select v-if="cateList" height="100vh" :items="cateList" :main-active-index.sync="active" @click-nav="clickLeft">
      <template #content>
        
        <div class="rightBox">
          <div v-if="checkCate.p_list && checkCate.p_list.length" class="level_1_list rightBoxItem">
            <!-- <div v-for="(item, index) in checkCate.p_list" :key="index">
              {{ item.store_name }}
            </div> -->
            <van-grid :border="false" :column-num="2">
              <van-grid-item class="rightBox-product" v-for="(item, index) in checkCate.p_list" :key="index" @click="goDetail(item.id)">
                <van-image :src="item.image + '?x-oss-process=image/resize,w_200/quality,q_60'" />
                <div class="title">{{ item.store_name }}</div>
              </van-grid-item>
            </van-grid>
            <div class="more" @click="goCate(checkCate.id)">查看更多 <van-icon name="arrow" /></div>
          </div>
          <div class="level_2_list rightBoxItem" v-for="(item, index) in checkCate.children" :key="index">
            <div>
              {{ item.text }}
            </div>
            <div v-if="item.p_list && item.p_list.length">
              <van-grid :border="false" :column-num="2">
                <van-grid-item class="rightBox-product" v-for="(c_list, index) in item.p_list" :key="index" @click="goDetail(c_list.id)">
                  <van-image :src="c_list.image + '?x-oss-process=image/resize,w_200/quality,q_60'" />
                  <div class="title">{{ c_list.store_name }}</div>
                </van-grid-item>
              </van-grid>
              <div class="more" @click="goCate(item.id)">查看更多 <van-icon name="arrow" /></div>
            </div>
            <van-empty v-else image-size="60" description="暂无商品" />
          </div>
        </div>
      </template>
    </van-tree-select>

    <tabbar></tabbar>
  </div>
</template>
<script>
import tabbar from '@/asComponents/tabbar/'
import Vue from 'vue';
import { TreeSelect, Grid, GridItem, Image as VanImage, Empty, Icon  } from 'vant';
import { h5CateIndex, h5ProductIndex } from '@/api/shop'

Vue.use(TreeSelect);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(VanImage);
Vue.use(Empty);
Vue.use(Icon);

export default  {
  components: {
    tabbar
  },
  data() {
    return {
      active: 0,
      items: [{ text: '分组 1' }, { text: '分组 2' }],
      cateList: [],
      checkCate: {},

      pagination: {
        current: 1,
        pageSize: 2,
      },
    }  
  },
  created () {
    h5CateIndex().then(res => {
      const set_d_son = function (arr) {
        for (var i = 0; i < arr.length; i++) {
          arr[i].text = arr[i].cate_name
          if (arr[i].children) {
            set_d_son(arr[i].children)
          }
        }
      }
      set_d_son(res.data.lists)
      console.log(res.data.lists)
      this.cateList = res.data.lists
      this.checkCate = res.data.lists[0] || {}

      if (this.cateList[0]) {
        this.clickLeft(0)
      }
    })
  },
  mounted () {
    
  },
  methods: {
    goCate (id) {
      this.$router.push({
        path: '/shop/productList',
        query: {
          cateId: id
        }
      })
    },
    goDetail (id) {
      this.$router.push({ path: '/shop/detail', query: { id: id } })
    },
    clickLeft (k) {
      console.log(k)
      this.checkCate = this.cateList[k]
      this.getCateProduct(this.checkCate)
      if (this.checkCate.children) {
        for (var i = 0; i < this.checkCate.children.length; i++) {
            this.getCateProduct(this.checkCate.children[i])
        }
      }
    },
    getCateProduct (cateData) {
      h5ProductIndex({
        cate_id: cateData.id,
        page: this.pagination.current,
        page_size: this.pagination.pageSize
      }).then(res => {
        cateData.p_list = res.data.lists.data
        this.$forceUpdate()
      })
    }
  }
}
</script>

<style scoped lang="less">
/deep/.van-tree-select__nav {
  padding-bottom: 50px;
  padding-bottom: calc(constant(safe-area-inset-bottom) + 50px);//兼容 IOS<11.2
  padding-bottom: calc(env(safe-area-inset-bottom) + 50px);//兼容 IOS>11.2
}
.rightBox {
  padding: 15px;

  padding-bottom: 50px;
  padding-bottom: calc(constant(safe-area-inset-bottom) + 50px);//兼容 IOS<11.2
  padding-bottom: calc(env(safe-area-inset-bottom) + 50px);//兼容 IOS>11.2

  .more {
    margin-top: 10px;
    padding-right: 6px;
    font-size: 12px;
    text-align: right;
  }

  .rightBoxItem {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: .5px solid #f5f5f5;

    /deep/.van-empty {
      padding: 10px 0;

      .van-empty__description {
        font-size: 12px;
        color: #eee;
      }
    }
  }

  .rightBoxItem:last-child {
    border-bottom: 0;
    margin-bottom: 0;
  }

  &-product {
    overflow: hidden;
    
    /deep/.van-grid-item__content {
      padding: 8px 6px;
    }

    .title {
      margin-top: 4px;
      width: 100%;
      font-size: 12px;
      white-space: nowrap;
      // white-space: nowrap;
      // text-overflow: ellipsis;
      overflow: hidden;
    }
    
  }

  .level_2_list {
    .title {

    }
  }
}
</style>